import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import config from "../../../config/apiConfig";
import validaDatos from "../../../functions/Comprobaciones";
import Loader from "../../general/Loader";
import { NumberFormatter } from "react-number-formatter";
import ReCAPTCHA from "react-google-recaptcha";

export default function ModalInfoCursoView({ showModal }) {

    ///////////////////////
    //     VARIABLES     //
    ///////////////////////
    const navigate = useNavigate();
    const [name, setName ]= useState('');
    const [email, setEmail ]= useState('');
    const [phone, setPhone] = useState();
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const myform = document.getElementById('form-info-curso');
    const recaptcha = useRef(null);
    const checkboxchecked  = useRef(null);
    const aux = 43;
    const [categoriasCursoEmpresa, setCategoriasCursoEmpresa] = useState([]);
    const data = {
        action: 'devuelveCursos', // devuelveCursosEmpresaAcreditadora
        idEmp: aux,
    };

    /***********************************************************
     * UseEffect carga los datos del select por medio de fetch *
     * *********************************************************/
    useEffect(() => {

        if(showModal) {
            const aux = document.getElementById("btn-solicita-infoo");
            if(aux) {
                aux.classList.remove("d-sm-block");
                aux.classList.add("d-none");
            }
        }

        const fetchData = async () => {
            try {
                // En vez de categorias tengo que devolver cursos
                const response = await fetch(config.API_BASE_URL + '/curso.php?idempresa=43&trcce_id='+12, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setCategoriasCursoEmpresa(result);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();

    }, []); // Obtener datos cuando cambia categoria

    /*
     * Función que setea los datos de select
     * **/
    const renderOpts = () => {
        if(categoriasCursoEmpresa) {
            return categoriasCursoEmpresa.map(curso => (
                <option value={curso.trcce_id} >{curso.nombrecursoacreditado}</option>
            ));
        }
    };

    /*
     * Función para enviar los datos
     * **/
    async function handleSubmit(event) {
        event.preventDefault(); // Prevenir redirección

        let opt = recaptcha.current.getValue();
        // Validar los datos del formulario
        if (validaDatos(name, email, phone, checkboxchecked.current.checked, opt, "informacion")) {
            setLoading(true);

            var recibido = document.getElementById("recibido");
            var badenlace = document.getElementById("badenlace");
            var select = document.getElementById("selectorCurso");
            const idcurso = select.value;
            const nombre_curso = select.options[select.selectedIndex].text;

            // Enviar datos a la API
            const formData = {
                nombre: name,
                email: email,
                telefono: phone,
                nombre_curso: nombre_curso,
                trcce_id: idcurso,
                idempresa: aux,
                tokenRecaptcha: opt,
                tipoSolicitud: "informacion"
            };

            // En vez de categorias tengo que devolver cursos
            await fetch(config.API_BASE_URL + '/alumnos.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(result => {
                    if (result) {
                        setLoading(false);
                        if (result.enlace === "Bad Enlace") {
                            if (myform) {
                                myform.classList.add("d-none");
                                badenlace.classList.remove("d-none");
                            }
                            // Enviar correo electrónico y generar PDF (en el servidor backend)
                        }
                        else {
                            // Ocultar formulario y mostrar botón de descarga
                            if (myform) {
                                myform.classList.add("d-none");
                                recibido.classList.remove("d-none");
                                setUrl(result.enlace)
                            }
                        }
                        // Enviar correo electrónico y generar PDF (en el servidor backend)
                    } else {
                        // Mostrar mensaje de error
                        console.log("ERROR: " + result);
                        setLoading(false); // Ocultar loader al finalizar fetch con error
                        navigate('/algo-mal'); // Redirigir a /algo-mal
                    }
                })
                .catch((error) => {
                    // Manejar error
                    console.log("ERROR CATCH: " + error);
                    setLoading(false); // Ocultar loader al finalizar fetch con error
                    navigate('/algo-mal'); // Redirigir a /algo-mal
                });
        }
    }

    /*
     * Función que indica si se ha clickado el checkbox
     * **/
    function clickCheckBox() {
        if(checkboxchecked.current.checked){
            document.getElementById("btncontacto").classList.remove("disabled");
            document.getElementById("msgacept").classList.add("d-none");
        }
        else {
            document.getElementById("btncontacto").classList.add(("disabled"));
            document.getElementById("msgacept").classList.remove("d-none");
        }
    }

    /*
     * Función para cerrar el Modal
     * **/
    function cerrarModal() {
        const aux = document.getElementById("btn-solicita-infoo");
        if(aux) {
            // Esto se usa para cuando el formulario solo se quiere mostrar en home de forma permanente
            // if (window.location.pathname !== '/') {
            //     aux.classList.add("d-none");
            // }
            aux.classList.remove("openmodal");
        }
    }

    const handleClick = () => {
        window.scrollTo({
            top: 750,
            behaviour: "smooth"
        });
    };


    /**************
     *   RETURN   *
     **************/
    return (
        <div className="d-sm-block container-info" id="btn-solicita-infoo">
            <div className="showmodal">
                <div className="overlay"></div>
                {loading && <Loader />}
                <div className="myContactForm bg-color-gs-secondary-a widget widget-quick-contact">
                    <button className="modal_close" onClick={cerrarModal}>✕</button>
                    <div className="title">Solicita información</div>
                    <div id="recibido" className="lead-result d-none insert-video margin-form" style={{minHeight: 350}}>
                        <br />
                        <div className="modal-response-message text-center">
                            <br/>
                            <h3>Tu solicitud ha sido enviada.</h3>
                            <br/>
                            Ya puedes descargar el PDF con la información de tu curso a continuación.
                            <br/>
                            Nuestro equipo de atención al alumno se pondrá en contacto contigo para resolver todas las dudas que tengas.
                            <br/><br/>
                            Gracias por confiar en Fp Prime.
                        </div>
                        <Link to={url} target="_blank" className="text-decoration-none">
                            <button className = "btn btn-outline-warning btn-sm m-5 solicitud-enviada-btn position-static" onClick={handleClick} id="downloadDossierLink"  >
                                <span>Ver el dosier <i className="fa-solid fa-arrow-down fa-lg"></i></span>
                            </button>
                        </Link>
                    </div>
                    <div id="badenlace" className="lead-result d-none insert-video margin-form" style={{minHeight: 350}}>
                        <br />
                        <div className="modal-response-message text-center">
                            <br/>
                            <h3>Ups... Parece que no encontramos el enlace.</h3>
                            <br/>
                            ¡No te preocupes!
                            <br/>
                            Nuestro equipo de atención al alumno se pondrá en contacto contigo para resolver todas las dudas que tengas.
                            <br/><br/>
                            Gracias por confiar en Fp Prime.
                        </div>
                    </div>
                    <form className="body margin-form row flat-contact-form" id="form-info-curso" onSubmit={handleSubmit}>
                        <input type="name" tabIndex="0" aria-label = "name" placeholder="Nombre" name="name_user" id="name-contact" required onChange={(e) => setName(e.target.value)}/>
                        <input type="email" tabIndex="0" aria-label = "email" placeholder="Escriba su email" name="email_user" id="email-contact" required onChange={(e) => setEmail(e.target.value)}/>
                        <span id="email-contact-help" className="error-text small"></span>
                        <NumberFormatter
                            placeholder = "Teléfono"
                            name = "phone_user"
                            id = "phone-contact"
                            required = "true"
                            aria-required = "true"
                            format = {"+34 ### ### ###"}
                            aria-label = "phone_number"
                            value = { phone }
                            getValue = {(n) => setPhone(n)}
                        />
                        <span id="phone-contact-help" className="error-text small"></span>
                        <select id="selectorCurso" placeholder="¿Qué curso te interesa?" required name="selector_user">
                            <option hidden value="">¿Qué ciclo te interesa?</option>
                            {renderOpts()}
                        </select>
                        <div className="error d-none error-select24">Escoge la formación en la que estás interesado</div>
                        <span id="formacion-contact-help" className="error-text small"></span>
                        <p className="pb-3 pt-3 m-0 w-100 justify-content-start align-content-start textwidth txt-gray">
                            <input id="ckbprivacidad" type="checkbox" aria-label = "ckbprivacidad" onClick={clickCheckBox} ref={checkboxchecked}/>
                            &nbsp; He leído y acepto los <a className="color-enlace txt-bold" href="/terminos-condiciones"> Términos y Condiciones</a> y deseo recibir información comercial.
                        </p>
                        <p id = "msgacept" className = "d-none alert-danger error-text small "> Debe aceptar la Política de privacidad. </p>
                        <ReCAPTCHA sitekey = { config.RECAPTCHA_SITE_KEY } ref = { recaptcha }/>
                        <p id = "recaptgacept" className = "d-none alert-danger error-text small "> Verifique que no es un bot. </p>
                        <div className = "submit-wrap mt-3 mb-4">
                            <button id = "btncontacto" className = "btn btn-outline-warning btn-sm w-100" style = {{ position: "inherit" }} type="submit">
                                <span className="txt-bold">ENVIAR Y DESCARGAR <i className="fa-solid fa-arrow-down fa-lg"></i></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}