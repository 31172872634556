import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import config from "../../config/apiConfig";

const Faqs = () => {

    const aux = 1;
    const [FaqsEmpresa, setFaqsEmpresa] = useState([]);
    const data = {
        action: 'devuelveFaqsEmpresa',
        idEmp: aux,
    };

    useEffect(() => {
        // En vez de categorias tengo que devolver faqs
        fetch(config.API_BASE_URL + '/avisoslegales.php?tipo=faqs', {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                // Recorremos el array de faqs y llamamos al método que genera el html con los datos
                setFaqsEmpresa(result);
            })
            .catch(error => {
                // Manejar errores de la solicitud
                console.error('Error:', error);
            });
    }, []);

    const renderFaqsRow = () => {
        if(Object.keys(FaqsEmpresa).length > 0) {
            return FaqsEmpresa.map((faq, index) => (
                <div className = "faqs-main">
                    <Accordion >
                        <AccordionSummary
                            expandIcon = {<ExpandMoreIcon />}
                            aria-controls = {`panel1a-content-${index}`}
                            id = {`panel1a-content-${index}`}>
                            <h3 className = "Details-title js-details-title"> { faq.titulo } </h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div dangerouslySetInnerHTML={{ __html: faq.contenido }} />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ));
        }
        else {
            return (
                <div>
                    <br />
                    <br />
                    <h4 className = "text-center"> Estamos trabajando en esta sección de preguntas frecuentes. <br/><br/>¡Pronto podrás encontrar aquí todas las respuestas a las dudas más comunes! </h4>
                    <br />
                </div>
            );
        }
    };

    return (
            <div className = "text-white presentation presentation-quienes pt-5">
                <div className = "infopoliticas">
                    <div className = "cuadro-borde m-1 ms-4 me-4 mt-3 mb-1 p-3 pt-4" id = "accordion">
                        <h1 className = "elegant-line-bottom text-center"> Preguntas Frecuentes </h1>
                        <>
                            { renderFaqsRow() }
                        </>
                    </div>
                </div>
            </div>
    );
}

export default Faqs;