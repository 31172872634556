import React from 'react';
import { Link } from 'react-router-dom';
import MasInfoCursoView from "../index/InfoCiclos/MasInfoCursoView"; // Importar Link para la navegación

export default function AlgoMal () {

    window.scrollTo({
        top: 0,
        behaviour: "smooth"
    });

        return (
            <>
                <div className="algo-mal-container">
                    {/* Contenedor principal */}
                    <div className="error-content">
                        {/* Emoji con borde gris y cara de indiferencia */}
                        <div className="error-emoji">
                            <span className="emoji-border"></span>
                            <span className="emoji-face"></span>
                        </div>

                        {/* Mensaje de error centrado */}
                        <h1>¡Uy! Algo salió mal...</h1>

                        {/* Botón "Volver al inicio" con enlace */}
                        <Link to="/">
                            <button className = "btn btn-outline-warning btn-sm m-5 p-2 position-static font-600">Volver al inicio</button>
                        </Link>
                    </div>
                </div>
                <MasInfoCursoView />
                <br />
            </>
        );
    }
