import React from "react";
import RamasIndex from "../InfoCiclos/RamasIndex";

export default function RamasView () {

    return (
        <div>
            <div className = "me-lg-5 ms-lg-5 px-lg-5">
                <div className = "row mt-lg-5 me-0 ms-0 justify-content-center content-center">
                    <h1>Encuentra tu FP</h1>
                    <span >Disponemos de Ciclos Formativos de Grado Medio y de Grado Superior en el ámbito de la Sanidad:</span>
                </div>
                {/* TODO: CATEGORIAS - Ahora como hay pocos ciclos y solo de sanidad mostramos los cursos en vez de las categorias */}
                {/*<div className = "row mt-lg-3 me-0 ms-0 justify-content-center content-center">*/}
                {/*    <div className="col-sm-4 justify-content-center container-text-aligned">*/}
                {/*        <a href="/cicloscategoria/3665/Sanidad" className="text-decoration-none">*/}
                {/*            <div className="rounded-circle cajatitulo m-3 shadow-lg pt-5 pb-5 text-center text-yellow">*/}
                {/*                <div className="my-icon">*/}
                {/*                    <i className="fa-solid fa-heart-pulse"></i>*/}
                {/*                </div>*/}
                {/*                <br />*/}
                {/*                <h4 className='text-dark-yellow'>Sanidad</h4>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    </div>*/}
                <RamasIndex id = { 43 }></RamasIndex>
            </div>
        </div>
    );
}