import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import ImgSecretaria from '../../../img/renders-centro-fp/render9.jpeg';
import ImgEntrada from "../../../img/renders-centro-fp/render1.png";
import ImgPrincipal from "../../../img/renders-centro-fp/render4-pasillo.png";
import ImgAula from "../../../img/renders-centro-fp/render3-aula.png";

export default function CarrouselPrincipal() {

    return (
        <div id = "carousel-container" className = "shadow-carousel mb-5">
            <script src = "../../../../node_modules/lazysizes/lazysizes.min.js" async></script>
            <Carousel id = "carousel_info" className = "box-news slide bg-white h-100" fade >
                <Carousel.Item>
                    <img
                        className = "lazyload d-block h-100"
                        data-src = { ImgPrincipal }
                        alt = "Pasillo Fp Prime"
                    />
                    <div className = "carousel-text-center animated fadeInUp">
                        <h1>Fp Prime</h1>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className = "lazyload d-block h-100"
                        data-src = { ImgEntrada }
                        alt = "Hall Centro Fp Prime"
                    >
                    </img>
                    <div className = "carousel-left-top-txt animated fadeInUp" >
                        <h1>Centro Fp Prime</h1>
                        <h5>
                            Formación rigurosa, específica y de calidad.
                        </h5>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className = "lazyload d-block h-100"
                        data-src = { ImgAula }
                        alt = "Aula Fp Prime"
                    />
                    <div className = "carousel-left-top-txt animated fadeInUp pt-3">
                        <h3>Aprende, crece y triunfa con Fp Prime.</h3>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className ="lazyload d-block h-100"
                        data-src = { ImgSecretaria }
                        alt = "Secretaria Fp Prime"
                    />
                    <div className = "text-white carousel-left-bottom-txt animated fadeInUp">
                        <h3>Estudia FP Oficial en Fp Prime</h3>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}