import React, {useEffect, useState} from 'react';
import config from "../../../config/apiConfig";

const Curso = ({ titulo, empresa, id, idEmpresa, categoria, imagen, empresaAcreditadora }) => {
    return (
        <div className="col-sm-4 justify-content-center container-text-aligned vistamenucurso"  key = { id }>
            <a href = {`/curso/${idEmpresa}/${id}`} className="text-decoration-none">
                <div className="rounded-circle cajatitulo m-3 shadow-lg pt-5 pb-5 text-center text-yellow">
                    <div className="my-icon">
                        <i className = { imagen }></i>
                    </div>
                    <h5 className='text-dark-yellow px-3 pt-1'>{ titulo } </h5>
                </div>
            </a>
        </div>
    );
}

const RamasIndex = ({id}) => {

    const aux = 43;
    const [categoriasCursoEmpresa, setCategoriasCursoEmpresa] = useState([]);
    const data = {
        action: 'devuelveCursosEmpresa', // devuelveCursosEmpresaAcreditadora
        idEmp: aux,
    };

    useEffect(() => {
        // En vez de categorias tengo que devolver cursos
        fetch(config.API_BASE_URL + '/curso.php?idempresa=43&trcce_id='+12, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                // Recorremos el array de cursos y llamamos al método que genera el html con los datos
                setCategoriasCursoEmpresa(result);
            })
            .catch(error => {
                // Manejar errores de la solicitud
                console.error('Error:', error);
            });
    }, [id]); // Obtener datos cuando cambia categoria

    const renderCursosRows = () => {
        if(categoriasCursoEmpresa) {
            return categoriasCursoEmpresa.map(curso => (
                <Curso
                    key = {curso.trcce_id}
                    id = {curso.trcce_id}
                    idEmpresa = {curso.idempresa}
                    empresa = {curso.nombreempresa}
                    empresaAcreditadora = {curso.nombreentidadacreditadora}
                    titulo = {curso.nombrecursoacreditado}
                    categoria = {curso.nombrecategoria}
                    imagen = {curso.nombrecursoacreditado.toLowerCase().includes("dietética") ? "fa-solid fa-apple-whole"
                        : (curso.nombrecursoacreditado.toLowerCase().includes("documentación") ? "fa-solid fa-book"
                        : "fa-solid fa-heart-pulse")}
                />
            ));
        }
    };

    return (
        <div className = "row mt-lg-3 px-5 mx-5 justify-content-center content-center">
            {renderCursosRows()}
        </div>
    );
};

export default RamasIndex;
