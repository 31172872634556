import logo from "../../img/LogoFPPrime-Blanco.png";
import "../../../node_modules/@popperjs/core/dist/umd/popper.min.js";
import React, {useState} from 'react';
import empresas from "./InfoCiclos/empresas";
import config from "../../config/apiConfig";


export default function Header(props) {

    const data = {
        // ? Quitamos esto temporalmente hasta que no tengamos más cursos
        // ? action: 'devuelveCategoriasEmpresa',
        action: 'devuelveCursos',
        idEmp: 43,
    };

    const [oposicion, setOposicion] = useState([]);

    // Se crea el menu
    React.useEffect(() => {
        if (!document.getElementById('dropdownEmpresas').hasChildNodes()) {
            // Obtén el contenedor del dropdown
            const dropdownEmpresas = document.getElementById('dropdownEmpresas');
            // Verifica que el elemento exista antes de manipularlo
            if (dropdownEmpresas) {
                // Genera dinámicamente las opciones del dropdown
                empresas.forEach(empresa => {
                    const option = document.createElement('li');
                    // Construir la URL con el id y nombre de la empresa
                    const url = `/listaciclos/${empresa.idEmpresa}/${empresa.nombreEmpresa}`;
                    if(empresa.idEmpresa === 43){
                        option.innerHTML = `<a class="dropdown-item" href="${url}">${empresa.nombreEmpresa} Pontevedra</a>`;
                    }
                    else {
                        option.innerHTML = `<a class="dropdown-item disabled" href="/">Fp Prime ${empresa.ubicacionEmpresa} (Proximamente...)</a>`;
                    }
                    dropdownEmpresas.appendChild(option);
                });
            }
            // Obtén el contenedor del dropdown
            const dropdownFP = document.getElementById('dropdownFP');
            // Verifica que el elemento exista antes de manipularlo
            if (dropdownFP) {
                // ? Quitamos esto temporalmente hasta que no tengamos más cursos
                // ? const option = document.createElement('li');
                // ? option.innerHTML = `<a class="my-dropdown-item"> Por Categorías </a>`;
                // ? dropdownFP.appendChild(option);
                // ? const optionn = document.createElement('li');
                // ? optionn.innerHTML = `<hr class="dropdown-divider"/>`;
                // ? dropdownFP.appendChild(optionn);
                fetch(config.API_BASE_URL + '/curso.php?idempresa=43&trcce_id='+12, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(result => {
                    // Recorremos el array de cursos y llamamos al método que genera el html con los datos
                    setOposicion(result);
                    result.forEach(categoria => {
                        const option = document.createElement('li');
                        // Construir la URL con el id y nombre de la empresa
                        // ? Cambiamos esto temporalmente hasta que no tengamos más cursos
                        // ? const url = `/cicloscategoria/${categoria.id}/${categoria.nombreCategoria}`;
                        const url = `/curso/${categoria.idempresa}/${categoria.trcce_id}`;
                        option.innerHTML = `<a class="dropdown-item" href="${url}">${categoria.nombrecursoacreditado}</a>`;
                        dropdownFP.appendChild(option);
                    });
                })
                .catch(error => {
                    // Manejar errores de la solicitud
                    console.error('DPA:', error);
                });
            }
        }
    }, [oposicion, data]);

    window.addEventListener("scroll", function() {

        let header = document.getElementById("header2");
        header.classList.toggle("sticky", window.scrollY > 0);

        let logo = document.getElementById("logo-header");
        logo.classList.toggle("stickyImg", window.scrollY > 0);

        let btn = document.getElementById("button-header");
        btn.classList.toggle("stickyBtn", window.scrollY > 0);

        let butt = document.getElementById("butt");
        butt.classList.toggle("stickyBtn", window.scrollY > 0);
        
        let miMenu = document.getElementById("miMenu");
        miMenu.classList.toggle("stickyBtn", window.scrollY > 0);

        let menuFp = document.getElementById("menuFP");
        menuFp.classList.toggle("stickyBtn", window.scrollY > 0);
        let dropdownFP = document.getElementById("dropdownFP");
        dropdownFP.classList.toggle("stickyBtn", window.scrollY > 0);

        let butt2 = document.getElementById("butt2");
        butt2.classList.toggle("stickyBtn", window.scrollY > 0);
    });

    let dropdowns = document.querySelectorAll('.menu-btn')
    dropdowns.forEach((dd)=>{
        dd.addEventListener('click', function (e) {
            var el = this.nextElementSibling
            el.style.display = el.style.display==='block'?'none':'block'
        })
    });

    /**
     * Función para abrir el modal de solicita info
     */
    function clickSolicitaInfo () {
        const aux = document.getElementById("btn-solicita-infoo");
        if(aux) {
            if(aux.classList.contains("d-none")){
                aux.classList.remove("d-none");
            }
            aux.classList.add("openmodal");
        }
    }

    return (
        <div className = "row bg-fpprime justify-content-end " id = "header2">
            <div className = "col-md-12 justify-content-end">
                <nav className = "navbar navbar-expand-lg w-100 pe-4 ps-4">
                    <a href = "/" className="go-index" ><img src = { logo } alt = "Logo FPprime" title = "Logo FPprime" id = "logo-header" /></a>
                    <button className = "navbar-toggler" type = "button" id = "button-header" data-bs-toggle = "collapse" data-bs-target = "#navbarNav" aria-controls = "navbarNav" aria-expanded = "false" aria-label = "Toggle navigation">
                        <span className = "fas fa-bars"></span>
                    </button>
                    <div className = "collapse navbar-collapse justify-content-end" id = "navbarNav">
                        <ul className = "navbar-nav ml-auto">
                            <li className = "me-3">
                                <a className = "nav-link" id = "butt" href = "/conocenos"><h6> Conócenos </h6></a>
                            </li>
                            <li className="nav-item me-3 dropdown">
                                <a className = "nav-link menu-btn" data-bs-toggle="dropdown" href="/" role="button" id="menuFP" aria-haspopup="true" aria-expanded="false">
                                    <h6> Ciclos Formativos </h6>
                                </a>
                                <ul className="dropdown-menu drop-container" aria-labelledby="menuFP" id="dropdownFP" >
                                </ul>
                            </li>
                            <li className="nav-item me-3 dropdown">
                                <a className = "nav-link menu-btn" href="/" type="button" id="miMenu" aria-expanded="false" data-bs-toggle="dropdown" data-toggle="dropdown">
                                    <h6> Nuestros Centros </h6>
                                </a>
                                <ul className="dropdown-menu drop-container" aria-labelledby="dropdownMenuLink" id="dropdownEmpresas">
                                </ul>
                            </li>
                            <li className = "me-3">
                                <a className = "nav-link"  id = "butt2" href = "#informacion"><h6> Contacta con nosotros </h6></a>
                            </li>
                            <li>
                                <a className = "btn btn-sm mybtn"  id = "solicita-info-btn" onClick = { clickSolicitaInfo } ><span className="myspan"> Solicita información </span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}
