import React from 'react';
import LogoMinisterio from "../../../img/general/LogoMinisterio.png";

export default function PqIconosView() {

    return (
        <div>
            <div className="container mt-5 mb-5">
                <h1 className="d-inline-block text-center"> ¿Por qué estudiar en Fp Prime? </h1>
                <div id = "counterdivscroll" className = "container mb-5  counterdivscroll">
                    <div className = "row mt-5 row-center">
                        <div className = "col-sm-2 go-top-container2 container-text-aligned" >
                            <div className = "text-center">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className = "my-icon" >
                                    <i className="fa-solid fa-user-check"></i>
                                </a>
                            </div>
                        </div>
                        <div className = "col-sm-4 go-top-container2 container-text-aligned text-center-small" >
                            <div className = "my-text-count">Bolsa de empleo</div>
                            <div style={{color: "gray"}}>
                                El servicio de orientación del centro se encarga de una búsqueda activa de empleo para nuestro alumnado.
                            </div>
                        </div>
                        <div className = "col-sm-2 go-top-container2 container-text-aligned margin-top-icons-small" >
                            <div className = "text-center" >
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className = "my-icon" >
                                    <i className="fa-solid fa-person-chalkboard"></i>
                                </a>
                            </div>
                        </div>
                        <div className = "col-sm-4 go-top-container2 container-text-aligned text-center-small" >
                            <div className = "my-text-count">Orientación</div>
                            <div style={{color: "gray"}}>
                                Servicio de orientación académica, personal y profesional.
                            </div>
                        </div>
                    </div>
                    <div className = "row mt-5 row-center row-margin-top-small" >
                        <div className = "col-sm-2 go-top-container2 container-text-aligned" >
                            <div className = "text-center" >
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className = "my-icon" >
                                    <i className="fa-solid fa-chalkboard-user"></i>
                                </a>
                            </div>
                        </div>
                        <div className = "col-sm-4 go-top-container2 container-text-aligned text-center-small" >
                            <div className = "my-text-count">FP Dual</div>
                            <div style={{color: "gray"}}>
                                La formación del alumnado se lleva a cabo entre el centro y la empresa.
                            </div>
                        </div>
                        <div className = "col-sm-2 go-top-container2 container-text-aligned margin-top-icons-small" >
                            <div className = "text-center" >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className = "my-icon" >
                                <i className="fa-solid fa-file-signature"></i>
                            </a>
                        </div>
                        </div>
                        <div className = "col-sm-4 go-top-container2 container-text-aligned text-center-small" >
                            <div className = "my-text-count">Titulaciones oficiales</div>
                            <div style={{color: "gray"}}>
                                Título FP Autorizado por el Ministerio de Educación <br />
                                <img className = "img-logo-ministerio" src = { LogoMinisterio } alt = "Logo Ministerio de Educación"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
}
