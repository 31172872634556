import React, {useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/fpprime.min.css';
import './css/loader.min.css';
import './css/fuentes.min.css';
import '../node_modules/lazysizes/lazysizes.min.js';
import Faqs from './components/Views/Faqs';
import Home from './components/Home';
// import AvisoLegal from './components/Views/AvisoLegal';
import Footer from "./components/general/footer";
import Header from "./components/index/Header";
import EncabezadoSuperior from "./components/index/header/EncabezadoSuperior";
import ConocenosView from "./components/Views/ConocenosView";
import CarouselColaboradores from "./components/index/body/CarouselColaboradores";
import CursosFPPrime from "./components/index/InfoCiclos/CursosFP-Prime";
import CursosFPCategorias from "./components/index/InfoCiclos/CursosFPCategorias";
import VistaCurso from "./components/index/InfoCiclos/VistaCurso";
import PruebaIndex from "./components/PruebaIndex";
import AlgoMal from "./components/general/AlgoMal";
import ModalPreinscripcionView from "./components/index/modals/ModalPreinscripcion";

const Cookies = React.lazy(() => import('./components/Views/Cookies'));
const AvisoLegal = React.lazy(() => import('./components/Views/AvisoLegal'));
const PoliticaPrivacidad = React.lazy(() => import('./components/Views/PoliticaPrivacidad'));
const TerminosCondiciones = React.lazy(() => import('./components/Views/TerminosCondiciones'));

export default function MyApp() {

    window.onscroll = function() {
        if (document.documentElement.scrollTop > 100) {
            document.querySelector(".go-top-container")
                .classList.add("show");
        } else {
            document.querySelector(".go-top-container")
                .classList.remove("show");
        }
    }
    const [isHome, setIsHome] = useState(false); // Estado para saber si está en la pantalla principal

    useEffect(() => {
        setIsHome(window.location.pathname !== '/'); // Actualizar estado al cambiar la ruta
    }, []);

    return (
        <BrowserRouter>
            <div>
                <EncabezadoSuperior/>
                <Header/>
                <Routes>
                    <Route path = "/" element = { <Home /> } />
                    <Route path = "/aviso_legal" element = { <AvisoLegal /> } />
                    <Route path = "/politica_privacidad" element = { <PoliticaPrivacidad /> } />
                    <Route path = "/faqs" element = { <Faqs/> } />
                    <Route path = "/politica_cookies" element = { <Cookies /> } />
                    <Route path = "/terminos-condiciones" element = { <TerminosCondiciones /> } />
                    <Route path = "/conocenos" element = { <ConocenosView /> } />
                    <Route path = "/listaciclos/:id/:nombre" element = { <CursosFPPrime /> } />
                    <Route path = "/cicloscategoria/:idcategoria/:categoria" element = { <CursosFPCategorias /> } />
                    <Route path = "/curso/:idEmpresa/:id" element = { <VistaCurso /> } />
                    {/*<Route path = "/prueba" element = { <PruebaIndex/> } />*/}
                    <Route path = "/algo-mal" element = { <AlgoMal/> } />
                </Routes>
                {/* Si solo tenemos el form en home */}
                {/*{isHome && <ModalInfoCursoView  showModal={true}/>}*/}
                <ModalPreinscripcionView />
                <br />
                <CarouselColaboradores />
                <section className = "informacion" id = "informacion" />
                <Footer/>
            </div>
        </BrowserRouter>
    );
}
