import React, { useState } from "react";
import validator from 'validator'
import { NumberFormatter } from 'react-number-formatter';

export default function Footer() {

    let fechaActual = new Date(); // fecha actual
    let anio = fechaActual.getFullYear();
    let checkboxchecked = false;
    const [email, setEmail ]= useState('')
    const [phone, setPhone] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    function enviar() {
        if (validator.isEmail(email)) {
            setEmailError('Valid Email :)')
        } else {
            setEmailError('Introduce un email válido')
        }
        if (validator.isMobilePhone(phone)) {
            setPhoneError('Valid Phone :)')
        } else {
            setPhoneError('Introduce un número de teléfono válido')
        }
    }

    function clickCheckBox() {
        if(checkboxchecked){
            document.getElementById("btncontacto").classList.add(("disabled"));
            document.getElementById("msgacept").classList.remove("d-none");
        }
        else {
            document.getElementById("btncontacto").classList.remove("disabled");
            document.getElementById("msgacept").classList.add("d-none");
        }
        checkboxchecked = !checkboxchecked;
    }

    window.onscroll = function() {
        if(document.documentElement.scrollTop > 100){
            document.querySelector(".go-top-container")
                .classList.add("show");
        }
        else {
            document.querySelector(".go-top-container")
                .classList.remove("show");
        }
    }

    // Go-To-Top Button responsive with footer
    window.addEventListener('scroll', function () {
        // Evento de Scroll
        let btn = document.getElementById("avisosfooter");
        let bottomValue = btn.offsetHeight + 10;
        if (window.scrollY + window.innerHeight === document.documentElement.scrollHeight) {
            // Si estamos al final de la página
            document.querySelectorAll('.go-top-container').forEach(function (element) {
                element.style.bottom = bottomValue + "px";
            });
        } else {
            // Si no
            document.querySelectorAll('.go-top-container').forEach(function (element) {
                element.style.bottom = "40px";
            });
        }
    });

    function irArriba() {
        window.scrollTo({
            top: 0,
            behaviour: "smooth"
        });
    }

    return (
        <div>
            <div className="text-white pt-5 mt-xxl-5 containerfooter shadow-footer">
                <div id="contacto">
                    <div className="row text-center m-auto">
                        <div className="col-lg-6">
                            <h4 className="ms-3 me-3">Contacto:</h4>
                            <div className="cuadro-borde m-2 ms-3 me-3 mt-3 pt-4 pb-4 mb-3">
                                <h5 className="mb-4">TELÉFONO:</h5>
                                <p className = "mb-4"> <a href = "tel:+34986319690" className = "highlight-emails white"> +34 986 319 690 </a></p>
                                <p>De Lunes a Viernes de 08:00h a 15:00h</p>
                                <div className="elegant-line-bottom2 mt-4" />
                                <h5 className="mt-4 mb-4">EMAIL:</h5>
                                <p className = "mb-4"> <a href = "mailto:info@fpprime.es" className = "highlight-emails white"> info@fpprime.es </a></p>
                                <p><a></a></p>
                                <i className="bi bi-chevron-double-right"></i>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="ms-3 me-3">¿Dónde estamos?</h4>
                            <div className="cuadro-borde m-2 ms-3 me-3 mt-3 pt-4 pb-4">
                                <h5 className="mb-4">Centro Fp Prime</h5>
                                <p>Rúa Santa Clara nº21, 36002 Pontevedra</p>
                                <p><u>Entrada por:</u> Rúa da Cruz Vermella 10 (bajo), esq. Praza da Castaña, 36002 Pontevedra</p>
                            </div>
                            <h4 className="mt-3 ms-3 me-3 mb-3">Síguenos:</h4>
                            <div className="cuadro-borde m-2 ms-3 me-3 mt-3 pt-4 pb-4 mb-3">
                                <div className="desktop">
                                    <div className="elegant-line-bottom2" />
                                    <div className="row text-center me-0 ms-0 align-content-center align-items-center justify-content-center padding-rrss"  >
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Facebook" href="https://www.facebook.com/fpprimepontevedra"  >
                                                <i className="fab fa-facebook" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Instagram" href="https://www.instagram.com/fp.prime"  >
                                                <i className="fab fa-instagram" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Twitter" href="https://twitter.com/fpprime"  >
                                                <i className="fab fa-x-twitter" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Tiktok" href="https://www.tiktok.com/@fp_prime"  >
                                                <i className="fab fa-tiktok" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Threads" href="https://www.threads.net/@fp.prime"  >
                                                <i className="fab fa-threads" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Linkedin" href="https://www.linkedin.com/company/fp-prime/"  >
                                                <i className="fab fa-linkedin" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Youtube" href="https://www.youtube.com/@FP-Prime"  >
                                                <i className="fab fa-youtube" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobile">
                                    <div className="elegant-line-bottom2" />
                                    <div className="row text-center me-0 ms-0 align-content-center align-items-center justify-content-evenly padding-rrss"  >
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Facebook" href="https://www.facebook.com/fpprimepontevedra"  >
                                                <i className="fab fa-facebook" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Instagram" href="https://www.instagram.com/fp.prime"  >
                                                <i className="fab fa-instagram" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Twitter" href="https://twitter.com/fpprime"  >
                                                <i className="fab fa-x-twitter" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Tiktok" href="https://www.tiktok.com/@fp_prime"  >
                                                <i className="fab fa-tiktok" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="elegant-line-bottom2" />
                                    <div className="row text-center align-content-center align-items-center justify-content-evenly padding-rrss margin-3elem-rrss"  >
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Threads" href="https://www.threads.net/@fp.prime"  >
                                                <i className="fab fa-threads" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1 my-margin">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Linkedin" href="https://www.linkedin.com/company/fp-prime/"  >
                                                <i className="fab fa-linkedin" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                        <div className="col-1">
                                            <div className="shared-grey" />
                                            <a className="shared" rel="nofollow" target="_blank" title="Youtube" href="https://www.youtube.com/@FP-Prime"  >
                                                <i className="fab fa-youtube" style={{color: "#ffffff", margin: "auto"}}></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-4">*/}
                        {/*    <h4 className="ms-3 me-3 mb-0">¿Alguna consulta?</h4>*/}
                        {/*    <div className="cuadro-borde m-2 ms-3 me-3 mt-3 pt-3 pb-4">*/}
                        {/*        <div className="widget widget-quick-contact">*/}
                        {/*            <form className="flat-contact-form" id="contactform" method="post" action="../../contact/contact-process.php" noValidate="novalidate">*/}
                        {/*                <input type="hidden" name="accion" value="contacto"/>*/}
                        {/*                <input type="hidden" name="recaptchaResponse2" id="recaptchaResponse2" value="" />*/}

                        {/*                <input type="email" tabIndex="0" aria-label = "email"  placeholder="Escriba su email" name="email_user" id="email-contact" required="" onChange={(e) => setEmail(e)}/>*/}
                        {/*                <span style={{ color: 'red' }}>{emailError}</span>*/}
                        {/*                <NumberFormatter*/}
                        {/*                    tabIndex="0" name="phone_user" id="phone-contact"*/}
                        {/*                    placeholder="Escriba su Teléfono"*/}
                        {/*                    format={"+34 ### ### ###"}*/}
                        {/*                    aria-label= "phone_number"*/}
                        {/*                    value={phone}*/}
                        {/*                    getValue={(n) => setPhone(n)}*/}
                        {/*                />*/}
                        {/*                <span style={{ fontWeight: 'bold', color: 'red' }}>{phoneError}</span>*/}
                        {/*                <textarea className="type-input" tabIndex="0" aria-label = "mensaje" placeholder="Escriba su Mensaje" name="message_user" id="message-contact" required=""></textarea>*/}
                        {/*                <p className="pb-3 pt-3 m-0 w-100 justify-content-start align-content-start textwidth">*/}
                        {/*                    <input id="ckbprivacidad" type="checkbox" aria-label = "ckbprivacidad" onClick={clickCheckBox}/>*/}
                        {/*                    &nbsp; He leído y acepto los <a className="color-enlace" href="/terminos-condiciones"> Términos y Condiciones</a>*/}
                        {/*                </p>*/}
                        {/*                <p id = "msgacept" className = "d-none alert-danger "> Debe aceptar la Política de privacidad. </p>*/}
                        {/*                <div className = "submit-wrap mt-3">*/}
                        {/*                    <input type = "hidden" name = "recaptchaResponse" id = "recaptchaResponse2" value = ""/>*/}
                        {/*                    <div id = "recaptcha1"></div>*/}
                        {/*                    <button id = "btncontacto" className = "btn btn-outline-warning btn-sm w-100" style = {{ position: "inherit" }} onClick = { enviar }> Enviar </button>*/}
                        {/*                </div>*/}
                        {/*            </form>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div id = "mycontainer">
                <div className = "go-top-container" id = "go-top-container">
                    <div className = "go-top-button" id = "go-top-button">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className = "go-top-arrow" onClick = { irArriba }>
                            <i onClick = { irArriba } className = "fas fa-chevron-up" />
                        </a>
                    </div>
                </div>
            </div>
            <div className = "avisosfooter" id = "avisosfooter">
                <div className = "container row m-auto pt-2 text-white">
                    <div className = "col-lg-6 text-center text-lg-start pb-lg-0 text-white text-shadow">
                        Copyright @ {anio} - fpprime.es | Todos los derechos reservados
                    </div>
                    <div className = "box-oppo col-lg-6 text-center text-lg-end text-white text-shadow">
                        <a href = "/aviso_legal" className = "text-decoration-none">Aviso Legal </a>  -
                        <a href = "/politica_privacidad" className = "text-decoration-none"> Política de Privacidad </a>  -
                        <a href = "/faqs" className = "text-decoration-none"> Preguntas Frecuentes </a>  -
                        <a href = "/politica_cookies" className = "text-decoration-none"> Política de Cookies </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
