
export default function EncabezadoSuperior() {

    /**
     * Función para abrir el modal de solicita info
     */
    function clickPreinscripcion () {
        // TODO: Cambiamos a solicita información hasta que se abra la preinscripción
        // const aux = document.getElementById("btn-preinscripcion");
        const aux = document.getElementById("btn-solicita-infoo");
        if(aux) {
            if(aux.classList.contains("d-none")){
                aux.classList.remove("d-none");
            }
            aux.classList.add("openmodal");
        }
    }

    return (
        <div className = "row bg-fpprime justify-content-end " id = "header">
            <div className = "col-md-12 offset-md-1 col-lg-8 offset-lg-3 justify-content-end top-header-advise">
                <ul className = "nav nav-pills flex-row justify-content-between">
                    <li>
                        <a  onClick = { clickPreinscripcion } className="text-decoration-none ps-4" id = "btn-preinsc-header">
                            <span className = "myspan light-yellow-text">
                                <i className="fa-solid fa-bolt"></i>
                                &nbsp;¡Infórmate!
                            </span>
                        </a>
                    </li>
                    <li className = "nav-item ">
                        <a className = "btn btn-sm mybtn" href = "https://gestionformativa.es/accesoalumnos.php?mp=43">
                            <span className = "myspan">
                                ACCESO CAMPUS
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}